/**
SMACSS http://smacss.com/
Layout rules divide the page into sections. Layouts hold one or more modules together.
*/

* {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;

}

html {
  box-sizing: border-box;
  font-size: 100%;
  height: 100vh;
  width: 100vw;
  transition: font-size 0.2s;
}


/**
SMACSS http://smacss.com/
Modules are the reusable, modular parts of our design. They are the callouts, the sidebar sections, the product lists and so on.
*/

.profile {
  &-link {
    text-decoration: underline;
    margin: 0 0.5rem;
  }
  &-username {
    font-weight: 700;
    margin: 0 0.6rem;
  }
}

.notes {
  &-title {
    margin: 0 2rem;
  }
  &-paste {
    & span {
      margin: 0.5rem;
      font-size: 1.3rem;
    }
  }
}

.item-list {
  display: flex;
  justify-content: space-between;
}

main {

  height: 100vh;
}

.hidden {
  display: none;
}

.selectedChapter {
  background-color: grey;
}

// PageHeader
.profile-container{
  position: relative;
  .avatar {
    position: absolute;
    width: 3rem;
    height: 3rem;
  }
  .profile-name{
    position: absolute;
    margin-left: 3.75rem;
    margin-top: .3rem;
  }
}

/* Scroolbar */
::-webkit-scrollbar {
  width: .2rem;
  height: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

.list-item:last-child {
  margin-bottom: 40px;
}